export const dispatchBrowserAgnosticEvent = function(element, eventName) {
  // Needed for IE Support: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/dispatchEvent#Browser_Compatibility
  // https://stackoverflow.com/a/49071358/79677
  let event;
  if (typeof(Event) === 'function') {
    event = new Event(eventName, { bubbles: true });
  } else {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  element.dispatchEvent(event);

  return event;
};

export function removeElementsFromDOM(elements) {
  elements.forEach(element => element.remove());
}
