import $ from 'jquery';

export default class Form {
  constructor(domElement) {
    this._domElement = domElement;
  }

  get data() {
    return new FormData(this.domElement);
  }

  get rawData() {
    const serialized = $(this.domElement).serializeArray();
    return serialized.reduce((rawData, field) => {
      rawData[field.name] = field.value;
      return rawData;
    }, {});
  }


  get domElement() {
    return this._domElement;
  }
}
