import _ from 'underscore';
import selectToAutocomplete from '../forms/selectToAutocomplete';
import { Form, Renderer, ValidatedForm, RemoteValidator } from '../forms/validation';
import { convertAuth0ErrorToFormErrors } from './validationIntegration';

export const formUtils = {
  showPending(formElement) {
    formElement.classList.add('loading');
  },

  hidePending(formElement) {
    formElement.classList.remove('loading');
  },

  focusFirstInput(formElement) {
    const input = formElement.querySelector('input');
    if (input !== null) { input.focus(); }
  },
};

class Auth0View {
  constructor({ client, host }) {
    this.client = client;
    this.host = host;
    this.onLoad = this.onLoad.bind(this);
  }

  get formValidationUrl() {
    return `${this.host}${this.formValidationPath}`;
  }

  buildRemotelyValidatedForm(formElement) {
    return new ValidatedForm(
      new Form(formElement),
      new RemoteValidator(this.formValidationUrl),
      new Renderer(formElement),
    );
  }

  validateFormOnInput(formElement) {
    this.buildRemotelyValidatedForm(
      formElement,
      this.formValidationUrl,
    ).bindValidateOnInput(this.formInputNamesToValidate);
  }

  validate(formElement) {
    const { form, validate } = this.buildRemotelyValidatedForm(formElement);
    return validate(this.formInputNamesToValidate)
      .then(errors => {
        if (_.isEmpty(errors)) {
          return form.rawData;
        } else {
          throw errors;
        }
      });
  }
}
_.extend(Auth0View.prototype, formUtils);

export class LoginView extends Auth0View {
  onLoad(rootElement) {
    const formElement = rootElement.querySelector('#login-form');
    this.focusFirstInput(formElement);
    this.bindLoginOnSubmit(formElement);
    this.validateFormOnInput(formElement);
  }

  bindLoginOnSubmit(formElement) {
    formElement.addEventListener('submit', event => {
      event.preventDefault();
      this.process(formElement);
    });
  }

  process(formElement) {
    return this.validate(formElement)
      .then(() => this.login(formElement));
  }

  login(formElement) {
    this.showPending(formElement);
    const form = new Form(formElement);
    const renderer = new Renderer(formElement);
    const formData = form.rawData;
    return this.client.login(formData).catch(error => {
      renderer.displayErrors(
        convertAuth0ErrorToFormErrors(error)
      );
      this.hidePending(formElement);
    });
  }
}

LoginView.prototype.formValidationPath = '/account/login/check/';
LoginView.prototype.formInputNamesToValidate = ['email', 'password'];


export class SignupView extends Auth0View {
  onLoad(rootElement) {
    const formElement = rootElement.querySelector('#signup-form');
    this.focusFirstInput(formElement);
    this.bindProcessOnSubmit(formElement);
    this.validateFormOnInput(formElement);
    selectToAutocomplete(formElement.querySelector('[name=country]'));
  }

  bindProcessOnSubmit(formElement) {
    formElement.addEventListener('submit', event => {
      event.preventDefault();
      this.process(formElement);
    });
  }

  process(formElement) {
    return this.validate(formElement)
      .then(() => this.signup(formElement));
  }

  signup(formElement) {
    const form = new Form(formElement);
    const renderer = new Renderer(formElement);
    this.showPending(formElement);
    return this.client.signupAndLogin(form.rawData)
      .catch(error => {
        renderer.displayErrors(convertAuth0ErrorToFormErrors(error));
        this.hidePending(formElement);
      });
  }
}
SignupView.prototype.formValidationPath = '/account/signup/check/';
SignupView.prototype.formInputNamesToValidate = [
  'email', 'username', 'password', 'first_name', 'last_name', 'country',
  'tcs',
];
