export const convertAuth0ErrorToFormErrors = (auth0Error) => {
  let description;
  if (auth0Error.code == 'invalid-user-data') {
    description = 'Invalid user data';
  } else {
    description = auth0Error.description;
  }

  return {
    __all__: [description],
  };
};
