import 'what-input';
import Foundation from 'foundation-sites';

function initFoundation() {
  Foundation.addToJquery($);
  $(document).foundation();
}

export {
  initFoundation
};
