import { createAnimation } from './utils';

export const spinnerAnimationUrl = 'https://auth-assets.mbed.com/spinner.json';

const fetchHtml = (url) => fetch(url).then(res => res.text());

export default class Controller {
  constructor(rootElement, viewsHtmlUrl) {
    this.rootElement = rootElement;
    this.views = [];
    this.viewsHtmlUrl = viewsHtmlUrl;
    this._viewsHtml = null;
  }

  get viewsHtml() {
    if (this._viewsHtml === null) {
      this._viewsHtml = fetchHtml(this.viewsHtmlUrl);
    }
    return this._viewsHtml;
  }

  addView(viewName, view) {
    return this.views[viewName] = view;
  }

  makeActive(viewName) {
    if (!this.views[viewName]) {
      throw Error(`Unknown view ${viewName}`);
    }
    const { onLoad, htmlUrl } = this.views[viewName];
    return this.replaceContentWithViewHtml(viewName).then(onLoad);
  }

  replaceContentWithViewHtml(viewName) {
    return this.viewsHtml.then(html => {
      const doc = (new DOMParser()).parseFromString(html, 'text/html');
      this.rootElement.innerHTML = '';
      this.rootElement.appendChild(
        doc.querySelector(`[data-view=${viewName}]`)
      );
      createAnimation(
        this.rootElement.querySelector('.pending-spinner'),
        spinnerAnimationUrl
      );
      return this.rootElement.firstChild;
    });
  }
}
