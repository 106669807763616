import _ from 'underscore';

export const DEBOUNCE_TIME = 500;

export default class ValidatedForm {
  constructor(form, validator, renderer) {
    this.form = form;
    this.validator = validator;
    this.renderer = renderer;
    this.validate = this.validate.bind(this);
  }

  validate(inputNames) {
    const validatedInputNames = ['__all__', ...inputNames];
    return this.validator.validate(this.form.data).then(errors => {
      const errorsForInputNames = _.pick(errors, inputNames);
      const invalidInputNames = _.keys(errorsForInputNames);
      const validInputNames = _.difference(validatedInputNames, invalidInputNames);
      this.renderer.displayValid(validInputNames);
      this.renderer.displayErrors(errorsForInputNames);
      return errors;
    });
  }

  bindValidateOnInput(inputNames) {
    const eventHandler = event => this.validate([event.target.name]);
    const debouncedHandlers = inputNames.reduce((handlers, inputName) => {
      handlers[inputName] = _.debounce(eventHandler, DEBOUNCE_TIME);
      return handlers;
    }, {});
    this.form.domElement.addEventListener('input', event => {
      const name = event.target.name;
      if (debouncedHandlers[name] !== undefined) {
        debouncedHandlers[name](event);
      }
    });
  }
}
