export function initMobileMenu(mobileMenuClassName) {
  const mobileMenu = document.querySelector('.' + mobileMenuClassName);
  const mobileMenuOpen = document.querySelector('.' + mobileMenuClassName + '-open');
  const mobileMenuOverlay = document.querySelector('.' + mobileMenuClassName + '-overlay');

  mobileMenuOpen.addEventListener('click', function() {
    mobileMenu.classList.add('active');
  }, false);

  window.addEventListener('click', function(event) {
    if (event.target === mobileMenuOverlay) {
      mobileMenu.classList.remove('active');
    }
  }, false);
}
