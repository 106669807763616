import Client from './client';
import Controller from './Controller';
import { LoginView, SignupView } from './views';

const initViewSwitch = (controller) => {
  document.addEventListener('click', (event) => {
    const dataset = event.target.dataset;
    if (dataset.switchViewTo) {
      controller.makeActive(dataset.switchViewTo);
    }
  });
};

export const init = (rootElement, auth0params, host = 'http://localhost:8000', login_hint = '') => {
  const client = Client.buildForAuth0Params(auth0params);

  const controller = new Controller(rootElement, `${host}/account/sign_in_forms/?login_hint=${encodeURIComponent(login_hint)}`);
  controller.addView('login', new LoginView({ client, host }));
  controller.addView('signup', new SignupView({ client, host }));
  controller.makeActive('login');

  initViewSwitch(controller);
};
