import $ from 'jquery';
import { attachFileUploader } from '../file-uploader';

window.mbed = window.mbed || {};
window.mbed.ImageUpload = (function(){
  var INPUT_SELECTOR = '.change-avatar__input';
  var IMAGE_SELECTOR = '.change-avatar__image';
  var UPLOADING_CLASS = 'change-avatar--uploading';

  function showStart($form) {
    $form.find('.errorlist').remove();
    $form.addClass(UPLOADING_CLASS);
    $form.find(INPUT_SELECTOR).attr('disabled', true);
  }

  function showDone($form) {
    $form.removeClass(UPLOADING_CLASS);
    $form.find(INPUT_SELECTOR).attr('disabled', false);
  }

  function showSuccess($form, uploadedImageUrl) {
    $form.find(IMAGE_SELECTOR).attr('src', uploadedImageUrl);
    showDone($form);
  }

  function showErrors($form, errors) {
    var $errors = errors.map(function(error) {
      return $('<li>').text(error);
    });
    $form.append(
      $('<ul>')
        .addClass('errorlist')
        .append($errors)
    );
    showDone($form);
  }

  function init(formId) {
    var $form = $(formId);
    var config = {
      url: $form.attr('action'),
      onError: function onError(errors) {
        var errorsList = [].concat(errors);
        showErrors($form, errorsList);
      },
      onSuccess: function onSuccess(uploadedImageUrl) {
        showSuccess($form, uploadedImageUrl);
      },
      onStart: function onStart() {
        showStart($form);
      },
    };
    attachFileUploader($form.find(INPUT_SELECTOR), config);
  }

  return {
    init: init,
  };
})();
