import _ from 'underscore';

const validateData = (endpoint, data) => {
  return fetch(endpoint, {
    method: 'POST',
    body: data,
  }).then(response => {
    return response.json();
  });
};

export default class RemoteValidator {
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  validate(formData) {
    return fetch(this.endpoint, {
      method: 'POST',
      body: formData,
    }).then(response => {
      return response.json();
    });
  }
}
