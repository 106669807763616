import './polyfills';
import svg4everybody from 'svg4everybody';
import {initFoundation} from './foundation-init';
import selectToAutocomplete from './forms/selectToAutocomplete';
import './forms/avatar-upload';
import '../js/configure-csrf';
import './mbed-nav-vertical-menu';
import * as FormsValidation from './forms/validation';
import * as Auth0 from './auth0';
import {initDismissableBanners} from '../js/banner-dismiss';
import {initNavNotification} from '../js/nav-notification';
import { initMobileMenu } from './mobile-menu';

window.mbed = window.mbed || {};
window.mbed.selectToAutocomplete = selectToAutocomplete;
window.mbed.FormsValidation = FormsValidation;
window.mbed.Auth0 = Auth0;
window.mbed.initDismissableBanners = initDismissableBanners;
window.mbed.initNavNotification = initNavNotification;
window.mbed.initMobileMenu = initMobileMenu;

initFoundation();
svg4everybody();
