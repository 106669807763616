import bodymovin from 'lottie-web';

export const loadAnimation = (container, animationData) => {
  bodymovin.loadAnimation({
    container: container,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: animationData
  });
};