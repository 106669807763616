import Awesomplete from 'awesomplete';
import { dispatchBrowserAgnosticEvent } from '../utils';

const generateUniqueId = (() => { 
  var id = 0; // Keep track of unique ids for autocomplete elements
  return function generateUniqueId() {
    return 'awesomeplete-' + id++;
  };
})();

const getSelectedText = (selectElement) => {
  const selectedOption = selectElement.querySelector('option[selected]');
  if (selectedOption.value === '') {
    return '';
  } else {
    return selectedOption.textContent;
  }
};

const getOptions = (selectElement) => {
  const options = {};
  const optionElements = selectElement.querySelectorAll('option');
  for (let i = 0; i < optionElements.length; i++) {
    const optionElement = optionElements[i];
    if (optionElement.value) { // Get rid of empty, placeholder value
      options[optionElement.textContent] = optionElement.value;
    }
  }
  return options;
};

const selectValue = (selectElement, value) => {
  selectElement.value = value;
  dispatchBrowserAgnosticEvent(selectElement, 'input');
  dispatchBrowserAgnosticEvent(selectElement, 'change');
};

const selectToAutocomplete = (selectElement) => {
  let lastSelectedText = getSelectedText(selectElement);
  const uniqueId = generateUniqueId();
  const options = getOptions(selectElement);

  const inputElement = document.createElement('input');
  inputElement.type = 'text';
  inputElement.id = uniqueId;
  inputElement.value = lastSelectedText;
  selectElement.parentNode.insertBefore(inputElement, selectElement);
  selectElement.style.display = 'none';

  new Awesomplete('#' + uniqueId, {
    list: Object.keys(options),
    minChars: 1,
  });

  function attemptSelectingValue(value) {
    var nextValue = options[value];
    if (nextValue !== undefined) {
      selectValue(selectElement, nextValue);
      lastSelectedText = value;
    } else {
      inputElement.value = lastSelectedText;
    }
  }

  inputElement.addEventListener('awesomplete-selectcomplete', event => {
    attemptSelectingValue(event.text.value);
  });
  inputElement.addEventListener('awesomplete-open', event => {
    inputElement.classList.add('open');
  });
  inputElement.addEventListener('awesomplete-close', event => {
    inputElement.classList.remove('open');
  });
  inputElement.addEventListener('change', event => {
    attemptSelectingValue(event.target.value);
  });
};

export default selectToAutocomplete;
