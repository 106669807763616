import auth0 from 'auth0-js';

export default class Client {
  constructor(webAuth) {
    this.webAuth = webAuth;
    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
    this.signupAndLogin = this.signupAndLogin.bind(this);
  }

  login({ email, password }) {
    return new Promise((resolve, reject) => {
      this.webAuth.login({
        realm: 'Username-Password-Authentication',
        email,
        password,
      }, function(err) {
        if (err) reject(err);
        resolve();
      });
    });
  }

  signup(userData) {
    return new Promise((resolve, reject) => {
      this.webAuth.signup({
        connection: 'Username-Password-Authentication',
        email: userData.email,
        password: userData.password,
        username: userData.username,
        user_metadata: {
          first_name: userData.first_name,
          last_name: userData.last_name,
          country: userData.country,
          marketing_consent: userData.marketing_consent,
          tcs: userData.tcs,
        }
      }, function(err) {
        if (err) reject(err);
        resolve();
      });
    });
  }

  signupAndLogin(userData) {
    return this.signup(userData).then(() => {
      return this.login({
        email: userData.email,
        password: userData.password
      });
    });
  }

  static buildForAuth0Params(auth0Params) {
    const webAuth = new auth0.WebAuth(auth0Params);
    return new Client(webAuth);
  }
}
