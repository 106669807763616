import $ from 'jquery';
import _ from 'underscore';

export default class Renderer {
  static renderErrors(errors) {
    const list = document.createElement('ul');
    list.classList.add('errorlist');
    for (let i = 0; i < errors.length; i++) {
      let li = document.createElement('li');
      li.textContent = errors[i];
      list.appendChild(li);
    }
    return list;
  }

  constructor(formElement) {
    this.formElement = formElement;
  }

  getFormControl(inputName) {
    return $(this.formElement)
      .find(`[name=${inputName}]`)
      .parents('.form-control')[0];
  }

  removeFailedValidationLists(element) {
    $(element).children('.errorlist, .warninglist').remove();
  }

  resetFormControl(formControlElement) {
    // .warning, .warninglist is rendered server side by django password field
    ['invalid', 'valid', 'warning'].forEach(className => {
      formControlElement.classList.remove(className);
    });
    this.removeFailedValidationLists(formControlElement);
  }

  resetForm(formElement) {
    this.removeFailedValidationLists(formElement);
  }

  _displayWithClassName(inputNames, className) {
    inputNames.forEach(inputName => {
      const formControl = this.getFormControl(inputName);
      this.resetFormControl(formControl);
      formControl.classList.add(className);
    });
  }

  displayValid(inputNames) {
    if (inputNames.indexOf('__all__') !== -1) {
      this.resetForm(this.formElement);
    }

    this._displayWithClassName(
      _.without(inputNames, '__all__'),
      'valid'
    );
  }

  displayErrors(errors) {
    const { __all__, ...inputErrors } = errors;

    if (__all__) {
      this.resetForm(this.formElement);
      this.formElement.prepend(Renderer.renderErrors(__all__));
    }

    for (const inputName in inputErrors) {
      const formControlElement = this.getFormControl(inputName);
      this.resetFormControl(formControlElement);
      formControlElement.classList.add('invalid');
      formControlElement.appendChild(Renderer.renderErrors(inputErrors[inputName]));
    }
  }
}
