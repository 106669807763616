import $ from 'jquery';

export function upload(file, fieldName, url, callbacks) {
  var formData = new FormData();
  formData.append(fieldName, file);
  callbacks.start();
  $.ajax({
    url: url,
    type: 'POST',
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
    success: function(data) {
      callbacks.success(data[fieldName]);
    },
    error: function(jqXHR, textStatus, errorThrown) {
      callbacks.error(
        (jqXHR.responseJSON && jqXHR.responseJSON[fieldName]) || errorThrown
      );
    },
  });
}

export function attachFileUploader(target, settings) {
  var noop = function(){};
  var callbacks = {
    error: settings.onError || noop,
    start: settings.onStart || noop,
    success: settings.onSuccess || noop,
  };
  target.on('change', function(event) {
    var file = event.target.files[0];
    if (null !== file) {
      upload(file, event.target.name, settings.url, callbacks);
    }
  });
}
