import ValidatedForm from './ValidatedForm';
import RemoteValidator from './RemoteValidator';
import Renderer from './Renderer';
import Form from './Form';

export const buildRemotelyValidatedForm = (formElement, validationUrl) => {
  return new ValidatedForm(
    new Form(formElement),
    new RemoteValidator(validationUrl),
    new Renderer(formElement),
  );
};

export { Form, Renderer, ValidatedForm, RemoteValidator };
