import { loadAnimation } from './animation';

export const convertFormDataToObject = (formData) => {
  const data = {};
  for (const [key, value] of formData) {
    data[key] = value;
  }
  return data;
};

export const createAnimation = (container, url) => {
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      loadAnimation(container, data);
    });
};
